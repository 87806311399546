'use client';

import * as React from 'react';
import { Autocomplete, IconButton, InputLabel, ListItem, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import { FieldValue, FormFieldType } from '@/types';
import { Dispatch, SetStateAction, memo, useCallback } from 'react';
import { BORDER_GREY, PRIMARY, PRIMARY_LIGHT, WHITE } from '@/constants/colors';
import { FieldType } from '@/enum';
import { DeleteOutline, DragIndicator, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parse } from 'date-fns';
import { TimePicker } from '@mui/x-date-pickers';
import AutoCompleteTextField from '../shared/AutoCompleteTextField';
import { enqueueSnackbar } from 'notistack';
import { useTranslations } from 'next-intl';
type SingleFieldProps = {
  id: string;
  index: number;
  label: string;
  value: FieldValue;
  type: FieldType;
  isDefault: boolean;
  isHidden: boolean;
  identifier: string;
  options?: string[];
  placeholder?: string;
  setFields: Dispatch<SetStateAction<FormFieldType[]>>;
};
const SingleField: React.FC<SingleFieldProps> = ({
  id,
  index,
  label,
  value,
  type,
  isDefault,
  isHidden,
  identifier,
  options,
  placeholder,
  setFields
}) => {
  const t = useTranslations('HOMEPAGE');
  const onChangeHandler = useCallback((val: FieldValue, fieldId: string, isLabel?: boolean) => {
    setFields(prev => prev.map(field => {
      if (field.id !== fieldId) return field;
      const updatedField = {
        ...field
      };
      if (isLabel && typeof val === 'string') {
        updatedField.label = val;
      } else {
        updatedField.value = val;
      }
      return updatedField;
    }));
  }, []);
  const getFieldByType = (id: string, type: FieldType, value: FieldValue, isHidden: boolean, identifier: string, options?: string[], placeholder?: string, isLabel?: boolean) => {
    switch (type) {
      case FieldType.TEXT_FIELD:
        return <TextField placeholder={placeholder} value={value} variant="outlined" hiddenLabel size="small" fullWidth disabled={isHidden} onChange={e => onChangeHandler(e.target.value, id, isLabel)} />;
      case FieldType.DATE:
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker value={parse(value as string, 'dd MMMM yyyy', new Date())} disabled={isHidden} onChange={value => {
            onChangeHandler(format(value as Date, 'dd MMMM yyyy'), id);
          }} format="dd MMMM yyyy" disableFuture slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              inputProps: {
                readOnly: true
              }
            }
          }} />
          </LocalizationProvider>;
      case FieldType.TIME:
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker disabled={isHidden} timeSteps={{
            hours: 1,
            minutes: 1,
            seconds: 1
          }} value={parse(value as string, 'hh:mm a', new Date())} onChange={value => {
            onChangeHandler(format(value as Date, 'hh:mm a'), id);
          }} slotProps={{
            textField: {
              size: 'small',
              fullWidth: true
            }
          }} />
          </LocalizationProvider>;
      case FieldType.SELECT:
        if (!options) return null;
        return <Select value={value} size="small" onChange={e => onChangeHandler(e.target.value, id)} fullWidth placeholder={placeholder}>
            {options.map(option => <MenuItem key={option} value={option}>
                {option}
              </MenuItem>)}
          </Select>;
      case FieldType.AUTOCOMPLETE:
        return <AutoCompleteTextField id={id} value={value} identifier={identifier} onChangeHandler={onChangeHandler} placeholder={placeholder} />;
      case FieldType.MULTI_SELECT:
        return <Autocomplete fullWidth size="small" value={value as string[]} onChange={(_, newValue: string[] | null) => {
          onChangeHandler(newValue, id);
        }} multiple options={options as string[]} freeSolo renderInput={params => <TextField placeholder={placeholder} {...params} hiddenLabel size="small" InputProps={{
          ...params.InputProps
        }} />}
        // renderTags={(value, getTagProps) =>
        //   value.map((option, index) => (
        //     <Chip
        //       variant="filled"
        //       label={option}
        //       color="primary"
        //       size="small"
        //       {...getTagProps({ index })}
        //     />
        //   ))
        // }
        />;
      default:
        return <TextField placeholder={placeholder} value={value} variant="outlined" hiddenLabel size="small" fullWidth disabled={isHidden} onChange={e => onChangeHandler(e.target.value, id, isLabel)} />;
    }
  };
  const getVisibilityAndDeleteIcon = (id: string, label: string, isDefault: boolean, isHidden: boolean, isDesktop: boolean): JSX.Element => {
    return <Tooltip title={isDefault ? isHidden ? t('SHOW') : t('HIDE') : t('DELETE')} data-sentry-element="Tooltip" data-sentry-component="getVisibilityAndDeleteIcon" data-sentry-source-file="SingleField.tsx">
        <IconButton size="small" color="primary" sx={{
        border: `1px solid ${BORDER_GREY}`,
        display: {
          xs: isDesktop ? 'none' : 'flex',
          sm: isDesktop ? 'flex' : 'none'
        },
        flexShrink: 0
      }} onClick={() => visibilityAndDeleteIconClickHandler(id, label, !isDefault)} data-sentry-element="IconButton" data-sentry-source-file="SingleField.tsx">
          {isDefault ? <>
              {isHidden ? <VisibilityOffOutlined fontSize="small" /> : <VisibilityOutlined fontSize="small" />}
            </> : <DeleteOutline fontSize="small" />}
        </IconButton>
      </Tooltip>;
  };
  const visibilityAndDeleteIconClickHandler = (id: string, label: string, isDelete: boolean) => {
    setFields(prev => {
      if (isDelete) {
        return prev.filter(field => field.id !== id);
      }
      return prev.map(field => {
        if (field.id === id && field.isDefault) {
          if (!field.isHidden) {
            enqueueSnackbar(`${label} ${t('HIDE_FIELD')}`, {
              variant: 'default',
              TransitionProps: {
                direction: 'up'
              }
            });
          }
          return {
            ...field,
            isHidden: !field.isHidden
          };
        }
        return field;
      });
    });
  };
  return <Draggable key={id} draggableId={id} index={index} data-sentry-element="Draggable" data-sentry-component="SingleField" data-sentry-source-file="SingleField.tsx">
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => <ListItem disablePadding ref={provided.innerRef} {...provided.draggableProps}>
          <Stack direction="row" alignItems="center" sx={{
        mb: {
          xs: 3,
          sm: 3
        },
        width: '100%'
      }} gap={2}>
            <Stack gap={1}>
              <IconButton size="small" color="primary" sx={{
            border: `1px solid ${BORDER_GREY}`,
            cursor: 'grab',
            flexShrink: 0
          }} disabled={isHidden} {...provided.dragHandleProps}>
                <DragIndicator fontSize="small" />
              </IconButton>
              {getVisibilityAndDeleteIcon(id, label, isDefault, isHidden, false)}
            </Stack>
            <Stack direction={{
          xs: 'column',
          sm: 'row'
        }} alignItems={{
          xs: 'flex-start',
          sm: 'center'
        }} gap={2} sx={{
          width: '100%',
          border: {
            xs: `1px solid ${snapshot.isDragging ? PRIMARY : BORDER_GREY}`,
            sm: 'none'
          },
          backgroundColor: {
            xs: snapshot.isDragging ? PRIMARY_LIGHT : WHITE,
            sm: WHITE
          },
          borderRadius: '4px',
          p: {
            xs: 2,
            sm: 0
          },
          opacity: isHidden ? 0.3 : 1,
          pointerEvents: isHidden ? 'none' : 'default'
        }}>
              <Stack sx={{
            width: {
              xs: '100%',
              sm: '30%'
            }
          }} flexDirection="row" alignItems="center">
                {isDefault ? <InputLabel sx={{
              color: snapshot.isDragging ? PRIMARY : 'initial',
              fontWeight: snapshot.isDragging ? 500 : 'initial'
            }}>
                    {label}
                  </InputLabel> : getFieldByType(id, type, label, isHidden, identifier, options, t('NEW_FIELD_NAME_PLACEHOLDER'), true)}
              </Stack>
              <Stack sx={{
            width: {
              xs: '100%',
              sm: '70%'
            }
          }} flexDirection="row" alignItems="center">
                {getFieldByType(id, type, value, isHidden, identifier, options, placeholder)}
              </Stack>
            </Stack>
            {getVisibilityAndDeleteIcon(id, label, isDefault, isHidden, true)}
          </Stack>
        </ListItem>}
    </Draggable>;
};
export default memo(SingleField);