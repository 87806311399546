import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const NAKSHATRA_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Ashwini',
    'Bharani',
    'Krittika',
    'Rohini',
    'Mrigashira',
    'Ardra',
    'Punarvasu',
    'Pushya',
    'Ashlesha',
    'Magha',
    'Purva Phalguni',
    'Uttara Phalguni',
    'Hasta',
    'Chitra',
    'Swati',
    'Vishakha',
    'Anuradha',
    'Jyeshtha',
    'Mula',
    'Purva Ashadha',
    'Uttara Ashadha',
    'Shravana',
    'Dhanishta',
    'Shatabhisha',
    'Purva Bhadrapada',
    'Uttara Bhadrapada',
    'Revati',
  ],
  [SupportedLanguage.Hindi]: [
    'अश्विनी',
    'भरणी',
    'कृत्तिका',
    'रोहिणी',
    'मृगशिरा',
    'आर्द्रा',
    'पुनर्वसु',
    'पुष्य',
    'आश्लेषा',
    'मघा',
    'पूर्वा फाल्गुनी',
    'उत्तरा फाल्गुनी',
    'हस्त',
    'चित्रा',
    'स्वाति',
    'विशाखा',
    'अनुराधा',
    'ज्येष्ठा',
    'मूल',
    'पूर्वाषाढ़ा',
    'उत्तराषाढ़ा',
    'श्रवण',
    'धनिष्ठा',
    'शतभिषा',
    'पूर्वा भाद्रपद',
    'उत्तरा भाद्रपद',
    'रेवती',
  ],
  [SupportedLanguage.Marathi]: [
    'अश्विनी',
    'भरणी',
    'कृत्तिका',
    'रोहिणी',
    'मृगशिरा',
    'आर्द्रा',
    'पुनर्वसू',
    'पुष्य',
    'आश्लेषा',
    'मघा',
    'पूर्वा फाल्गुनी',
    'उत्तर फाल्गुनी',
    'हस्त',
    'चित्रा',
    'स्वाती',
    'विशाखा',
    'अनुराधा',
    'ज्येष्ठा',
    'मूळ',
    'पूर्वाषाढा',
    'उत्तराषाढा',
    'श्रवण',
    'धनिष्ठा',
    'शतभिषा',
    'पूर्वा भाद्रपदा',
    'उत्तर भाद्रपदा',
    'रेवती',
  ],
  [SupportedLanguage.Gujarati]: [
    'અશ્વિની',
    'ભરણી',
    'કૃત્તિકા',
    'રોહિણી',
    'મૃગશિરા',
    'આર્દ્રા',
    'પુનર્વસુ',
    'પુષ્ય',
    'આશ્લેષા',
    'મઘા',
    'પૂર્વ ફાલ્ગુની',
    'ઉત્તર ફાલ્ગુની',
    'હસ્ત',
    'ચિત્રા',
    'સ્વાતિ',
    'વિશાખા',
    'અનુરાધા',
    'જ્યેષ્ઠા',
    'મૂળ',
    'પૂર્વાષાઢા',
    'ઉત્તરાષાઢા',
    'શ્રવણ',
    'ધનિષ્ઠા',
    'શતભિષા',
    'પૂર્વાભાદ્રપદ',
    'ઉત્તરાભાદ્રપદ',
    'રેવતી',
  ],
}
