import { HEADER_ICON_OPTIONS } from '@/app/api/data/HeaderIconOptions'
import { HEADER_TEXT_OPTIONS } from '@/app/api/data/HeaderTextOptions'
import { TEMPLATE_LIST } from '@/app/api/data/TemplateMetaData'
import { FORM_DATA_LOCAL_STORAGE_KEY } from '@/constants/keys'
import { SupportedLanguage } from '@/enum'
import { HeaderIcon, LocalStorageFormData, Template } from '@/types'

// Utility function to get data from local storage
export const getLocalStorageData = (key: string): LocalStorageFormData | null => {
  const localStorageDataString = localStorage.getItem(key)
  return localStorageDataString ? JSON.parse(localStorageDataString) : null
}

// Utility function to set data in local storage
export const setLocalStorageData = (key: string, data: LocalStorageFormData): void => {
  localStorage.setItem(key, JSON.stringify(data))
}

// Utility function to get the selected template from local storage
export const getTemplate = (): Template => {
  const localStorageData: LocalStorageFormData | null = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY)
  if (localStorageData) {
    return TEMPLATE_LIST.find((t) => t.id === localStorageData.selectedTemplateId) || TEMPLATE_LIST[0]
  }
  return TEMPLATE_LIST[0]
}

// Utility function to set the selected template ID in local storage
export const setSelectedTemplateIdInLocalStorage = (id: string): void => {
  const localStorageData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY) || ({} as LocalStorageFormData)
  const updatedData: LocalStorageFormData = { ...localStorageData, selectedTemplateId: id }
  setLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY, updatedData)
}

// Utility function to get the selected header icon from local storage
export const getSelectedHeaderIcon = (): HeaderIcon | null => {
  const localStorageData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY)
  if (localStorageData) {
    const selectedIcon = HEADER_ICON_OPTIONS.find((icon) => icon.id === localStorageData.selectedHeaderIconId)
    return selectedIcon || HEADER_ICON_OPTIONS[0]
  }
  return HEADER_ICON_OPTIONS[0]
}

// Utility function to set the selected header icon in local storage
export const setSelectedHeaderIconInLocalStorage = (id: string): void => {
  const localStorageData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY) || ({} as LocalStorageFormData)
  const updatedData: LocalStorageFormData = { ...localStorageData, selectedHeaderIconId: id }
  setLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY, updatedData)
}

// Utility function to get the selected header text from local storage
// Utility function to get the  header text from local storage
export const getSelectedHeaderText = (locale: SupportedLanguage): string | null => {
  const localStorageData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY)

  // Filter HEADER_TEXT_OPTIONS based on the provided locale
  const filteredOptions = HEADER_TEXT_OPTIONS.filter((option) => option.locale.includes(locale))

  // If localStorageData exists, return the selectedHeaderText
  if (localStorageData && localStorageData.selectedHeaderText) {
    return localStorageData.selectedHeaderText
  }

  // If no localStorageData, return the first matching option or default to the first option
  return filteredOptions.length > 0 ? filteredOptions[0].text : HEADER_TEXT_OPTIONS[0].text
}

// Utility function to set the selected header text in local storage
export const setSelectedHeaderTextInLocalStorage = (text: string): void => {
  const localStorageData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY) || ({} as LocalStorageFormData)
  const updatedData: LocalStorageFormData = { ...localStorageData, selectedHeaderText: text }
  setLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY, updatedData)
}
