import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const HOBBIES_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Badminton',
    'Basketball',
    'Coding',
    'Cooking',
    'Crafting',
    'Cricket',
    'Dancing',
    'Football',
    'Gardening',
    'Handicraft',
    'Hiking',
    'Listening to Music',
    'Painting',
    'Photography',
    'Playing Video Games',
    'Reading',
    'Singing',
    'Sports',
    'Tennis',
    'Traveling',
    'Trekking',
    'Volunteering',
    'Watching Movies',
    'Working out',
    'Writing',
    'Yoga',
  ],
  [SupportedLanguage.Hindi]: [
    'बैडमिंटन',
    'बास्केटबॉल',
    'कोडिंग',
    'खाना बनाना',
    'शिल्पकारी',
    'क्रिकेट',
    'नृत्य',
    'फुटबॉल',
    'बागवानी',
    'हस्तशिल्प',
    'पैदल यात्रा',
    'संगीत सुनना',
    'चित्रकला',
    'फोटोग्राफी',
    'वीडियो गेम खेलना',
    'पढ़ना',
    'गाना',
    'खेल',
    'टेनिस',
    'यात्रा करना',
    'ट्रेकिंग',
    'स्वयंसेवा',
    'फिल्में देखना',
    'व्यायाम करना',
    'लेखन',
    'योग',
  ],
  [SupportedLanguage.Marathi]: [
    'बॅडमिंटन',
    'बास्केटबॉल',
    'कोडिंग',
    'स्वयंपाक',
    'शिल्पकला',
    'क्रिकेट',
    'नृत्य',
    'फुटबॉल',
    'बागकाम',
    'हस्तकला',
    'पायी भटकंती',
    'संगीत ऐकणे',
    'चित्रकला',
    'फोटोग्राफी',
    'व्हिडिओ गेम खेळणे',
    'वाचन',
    'गाणे',
    'खेळ',
    'टेनिस',
    'प्रवास',
    'ट्रेकिंग',
    'स्वयंसेवा',
    'चित्रपट पाहणे',
    'व्यायाम',
    'लेखन',
    'योग',
  ],
  [SupportedLanguage.Gujarati]: [
    'બેડમિન્ટન',
    'બાસ્કેટબોલ',
    'કોડિંગ',
    'રસોઈ',
    'ક્રાફ્ટિંગ',
    'ક્રિકેટ',
    'નૃત્ય',
    'ફૂટબોલ',
    'બાગકામ',
    'હસ્તકલા',
    'હાઇકિંગ',
    'સંગીત સાંભળવું',
    'ચિત્રકામ',
    'ફોટોગ્રાફી',
    'વિડિઓ ગેમ્સ રમવા',
    'વાંચન',
    'ગાયન',
    'રમતો',
    'ટેનિસ',
    'મુસાફરી',
    'ટ્રેકિંગ',
    'સ્વયંસેવા',
    'મૂવીઝ જોવા',
    'કસરત',
    'લેખન',
    'યોગ',
  ],
}
