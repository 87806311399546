import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const MOTHER_TONGUE_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Hindi-Delhi',
    'Hindi-Madhya Pradesh/Chhattisgarh',
    'Hindi-Uttar Pradesh/Uttarakhand',
    'Punjabi',
    'Hindi-Bihar/Jharkhand',
    'Hindi-Rajasthan',
    'Haryanvi',
    'Himachali',
    'Kashmiri',
    'Sindhi',
    'Urdu',
    'Marathi',
    'Gujarati',
    'Kutchi',
    'Konkani',
    'Sindhi',
    'Tamil',
    'Telugu',
    'Kannada',
    'Malayalam',
    'Tulu',
    'Urdu',
    'Bengali',
    'Oriya',
    'Assamese',
    'Sikkim/Nepali',
    'English',
  ],
  [SupportedLanguage.Hindi]: [
    'हिंदी-दिल्ली',
    'हिंदी-मध्य प्रदेश/छत्तीसगढ़',
    'हिंदी-उत्तर प्रदेश/उत्तराखंड',
    'पंजाबी',
    'हिंदी-बिहार/झारखंड',
    'हिंदी-राजस्थान',
    'हरियाणवी',
    'हिमाचली',
    'कश्मीरी',
    'सिंधी',
    'उर्दू',
    'मराठी',
    'गुजराती',
    'कच्छी',
    'कोंकणी',
    'सिंधी',
    'तमिल',
    'तेलुगु',
    'कन्नड़',
    'मलयालम',
    'तुलु',
    'उर्दू',
    'बंगाली',
    'उड़िया',
    'असमिया',
    'सिक्किमी/नेपाली',
    'अंग्रेज़ी',
  ],
  [SupportedLanguage.Marathi]: [
    'हिंदी-दिल्ली',
    'हिंदी-मध्य प्रदेश/छत्तीसगड',
    'हिंदी-उत्तर प्रदेश/उत्तराखंड',
    'पंजाबी',
    'हिंदी-बिहार/झारखंड',
    'हिंदी-राजस्थान',
    'हरियाणवी',
    'हिमाचली',
    'काश्मीरी',
    'सिंधी',
    'उर्दू',
    'मराठी',
    'गुजराती',
    'कच्छी',
    'कोंकणी',
    'सिंधी',
    'तामिळ',
    'तेलुगू',
    'कन्नड',
    'मल्याळम',
    'तुळू',
    'उर्दू',
    'बंगाली',
    'ओडिया',
    'आसामी',
    'सिक्कीम/नेपाली',
    'इंग्रजी',
  ],
  [SupportedLanguage.Gujarati]: [
    'હિન્દી-દિલ્હી',
    'હિન્દી-મધ્ય પ્રદેશ/છત્તીસગઢ',
    'હિન્દી-ઉત્તર પ્રદેશ/ઉત્તરાખંડ',
    'પંજાબી',
    'હિન્દી-બિહાર/ઝારખંડ',
    'હિન્દી-રાજસ્થાન',
    'હરિયાણવી',
    'હિમાચલી',
    'કાશ્મીરી',
    'સિંધી',
    'ઉર્દુ',
    'મરાઠી',
    'ગુજરાતી',
    'કચ્છી',
    'કોંકણી',
    'સિંધી',
    'તમિલ',
    'તેલુગુ',
    'કન્નડ',
    'મલયાલમ',
    'તુલુ',
    'ઉર્દુ',
    'બંગાળી',
    'ઓડિયા',
    'અસમીસ',
    'સિક્કિમ/નેપાળી',
    'અંગ્રેજી',
  ],
}
