'use client';

import { List } from '@mui/material';
import { FormFieldType } from '@/types';
import { Dispatch, SetStateAction, memo } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import SingleField from './SingleField';
type FieldListProps = {
  fields: FormFieldType[];
  setFields: Dispatch<SetStateAction<FormFieldType[]>>;
};
const FieldList: React.FC<FieldListProps> = ({
  fields,
  setFields
}) => {
  const handleOnDragEnd = ({
    source,
    destination
  }: DropResult) => {
    // If the destination is null, the item was dropped outside the list
    if (!destination) return;
    // Create a copy of the items array
    const reorderedItems = Array.from(fields);
    // Remove the item from the source index
    const [movedItem] = reorderedItems.splice(source.index, 1);
    // Insert the item at the destination index
    reorderedItems.splice(destination.index, 0, movedItem);
    // Update the state with the new order of items
    setFields(reorderedItems);
  };
  return <DragDropContext onDragEnd={handleOnDragEnd} data-sentry-element="DragDropContext" data-sentry-component="FieldList" data-sentry-source-file="FieldList.tsx">
      <Droppable droppableId="biodatasection" data-sentry-element="Droppable" data-sentry-source-file="FieldList.tsx">
        {provided => <List {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map(({
          id,
          label,
          value,
          type,
          isDefault,
          isHidden,
          options,
          placeholder,
          identifier
        }, index) => <SingleField key={id} id={id} index={index} label={label} value={value} type={type} isDefault={isDefault} isHidden={isHidden} options={options} placeholder={placeholder} identifier={identifier} setFields={setFields} />)}
            {provided.placeholder}
          </List>}
      </Droppable>
    </DragDropContext>;
};
export default memo(FieldList);