import { FormFieldType } from '@/types'
import { PDFDocument } from 'pdf-lib'
import { Canvg } from 'canvg'

export const processPersonalDetails = (personalDetails: FormFieldType[]): FormFieldType[] => {
  const specializationField = personalDetails.find((field) => field.identifier === 'specialization' && !field.isHidden && field.value)

  const specializationValue = specializationField ? (specializationField.value as string) : ''

  return personalDetails
    .filter((field) => field.identifier !== 'specialization' && !field.isHidden)
    .map((field) => {
      if (field.identifier === 'education' && specializationValue) {
        return {
          ...field,
          value: `${field.value} - ${specializationValue}`,
        }
      }
      if (Array.isArray(field.value)) {
        return {
          ...field,
          value: field.value.join(', '),
        }
      }
      return field
    })
    .filter((field) => field.value && (!Array.isArray(field.value) || field.value.length > 0) && (field.value as string).length > 0)
}

export const mergePdfs = async (backgroundPdf: ArrayBuffer, contentPdf: ArrayBuffer) => {
  const backgroundDoc = await PDFDocument.load(backgroundPdf)
  const contentDoc = await PDFDocument.load(contentPdf)
  const [contentPage] = contentDoc.getPages()

  const embeddedPage = await backgroundDoc.embedPage(contentPage)

  const backgroundPage = backgroundDoc.getPage(0)
  const { width, height } = backgroundPage.getSize()
  backgroundPage.drawPage(embeddedPage, {
    x: 0,
    y: 0,
    width,
    height,
  })

  const mergedPdf = await backgroundDoc.save()
  return mergedPdf
}

export const svgToPng = async (svgPath: string, width: number, height: number, pathColor?: string): Promise<string | null> => {
  try {
    const response = await fetch(svgPath)
    const svgString = await response.text()

    // Only modify the SVG string if pathColor is provided
    const modifiedSvgString = pathColor ? svgString.replace(/fill="[^"]*"/g, `fill="${pathColor}"`) : svgString

    const canvas: HTMLCanvasElement = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const context = canvas.getContext('2d')
    if (context) {
      const v = await Canvg.from(context, modifiedSvgString.trim())
      await v.render()
      const dataUri = canvas.toDataURL('image/png')
      return dataUri
    } else {
      console.error('Error: Unable to get canvas context')
      return null
    }
  } catch (error) {
    console.error('Error occurred:', error)
    return null
  }
}

export const pxToPt = (px: number): number => {
  const conversionFactor = 72 / 96
  return px * conversionFactor
}

export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() ?? null
  }
  return null
}

export const generateWhatsAppMessage = (
  personalDetails: FormFieldType[],
  familyDetails: FormFieldType[],
  contactDetails: FormFieldType[],
  personalDetailsTitle: string,
  familyDetailsTitle: string,
  contactDetailsTitle: string,
): string => {
  let message = ''
  message += `*${personalDetailsTitle}:*\n`
  personalDetails.forEach((field) => {
    const { identifier, value, label, isHidden } = field

    // Special case for 'specialization' when there's no value or it's hidden
    if (identifier === 'specialization' && (!value || isHidden)) {
      message += '\n'
    }

    // Skip processing for hidden fields or fields without a value
    if (isHidden || !value) return

    if (Array.isArray(value) && value.length > 0) {
      // Handle array values for hobbies and languages
      if (identifier === 'hobbies' || identifier === 'languages') {
        message += `${label}: ${value.join(', ')}\n`
        return
      }
    }

    switch (identifier) {
      case 'education':
        message += `${label}: ${value}`
        break
      case 'specialization':
        // Append specialization value if it exists and is not hidden
        message += ` - ${value}\n`
        break
      default:
        // Handle all other identifiers that are not arrays or special cases
        if (typeof value !== 'object') {
          message += `${label}: ${value}\n`
        }
        break
    }
  })
  message += `\n*${familyDetailsTitle}:*\n`
  familyDetails.forEach((field) => {
    if (field.value && !field.isHidden) {
      message += `${field.label}: ${field.value}\n`
    }
  })
  message += `\n*${contactDetailsTitle}:*\n`
  contactDetails.forEach((field) => {
    if (field.value && !field.isHidden) {
      message += `${field.label}: ${field.value}\n`
    }
  })
  return message
}
