'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';
import { Settings, SettingsContextType } from '@/types';
import { SupportedLanguage, SupportedReligion } from '@/enum';
const SettingsContext = createContext<SettingsContextType | undefined>(undefined);
const defaultSettings: Settings = {
  language: SupportedLanguage.English,
  religion: SupportedReligion.Hinduism
};
const localStorageKey = 'MBM_SETTINGS';
const getInitialSettings = (): Settings => {
  if (typeof window !== 'undefined') {
    const storedSettings = localStorage.getItem(localStorageKey);
    return storedSettings ? JSON.parse(storedSettings) : defaultSettings;
  }
  return defaultSettings;
};
type SettingsProviderProps = {
  children?: React.ReactNode;
};
const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children
}) => {
  const initialSettings = getInitialSettings();
  const [settings, setSettings] = useState<Settings>(initialSettings);
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(settings));
  }, [settings]);
  const updateSetting = (key: string, value: string) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [key]: value
    }));
  };
  return <SettingsContext.Provider value={{
    settings,
    updateSetting
  }} data-sentry-element="unknown" data-sentry-component="SettingsProvider" data-sentry-source-file="SettingsProvider.tsx">
      {children}
    </SettingsContext.Provider>;
};
export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
export default SettingsProvider;