export const PRIMARY = '#D8465C'
export const SECONDARY = '#F8AC39'
export const SUCCESS = '#26CBA3'
export const PRIMARY_LIGHT = '#D8465C14'
export const WHITE = '#ffffff'
export const TEXT_DARK = '#34495E'
export const BORDER_GREY = '#e3e9ee'
export const BACKGROUND_PDF_VIEW = '#606B72'
export const TEMPLATE_LIST_BG = 'hsla(215, 15%, 97%, 0.5)'
export const TEXT_LIGHT = '#768493'
export const BLACK = '#000000'
