import { v4 as uuidv4 } from 'uuid'
import { FormSectionLangFieldMap } from '@/types'
import { FieldType, SupportedLanguage } from '@/enum'

export const CONTACT_DETAILS_DATA: FormSectionLangFieldMap = {
  [SupportedLanguage.English]: [
    {
      id: uuidv4(),
      label: 'Contact Person',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'Shivaji Verma',
      identifier: 'contactname',
    },
    {
      id: uuidv4(),
      label: 'Contact Number',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '9090909090',
      identifier: 'contactnumber',
    },
    {
      id: uuidv4(),
      label: 'Residential Address',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '503, Premsagar Society, LBS Marg, Kurla (West), Mumbai - 400070',
      identifier: 'address',
    },
  ],
  [SupportedLanguage.Hindi]: [
    {
      id: uuidv4(),
      label: 'संपर्क व्यक्ति',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'शिवाजी वर्मा',
      identifier: 'contactname',
    },
    {
      id: uuidv4(),
      label: 'संपर्क नंबर',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '9090909090',
      identifier: 'contactnumber',
    },
    {
      id: uuidv4(),
      label: 'आवासीय पता',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '503, प्रेमसागर सोसाइटी, एलबीएस मार्ग, कुर्ला (पश्चिम), मुंबई - 400070',
      identifier: 'address',
    },
  ],
  [SupportedLanguage.Marathi]: [
    {
      id: uuidv4(),
      label: 'संपर्क व्यक्ती',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'शिवाजी वर्मा',
      identifier: 'contactname',
    },
    {
      id: uuidv4(),
      label: 'मो.नं.',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '९०९०९०९०९०',
      identifier: 'contactnumber',
    },
    {
      id: uuidv4(),
      label: 'निवासी पत्ता',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '५०३, प्रेमसागर सोसायटी, एलबीएस मार्ग, कुर्ला (पश्चिम), मुंबई - ४०००७०',
      identifier: 'address',
    },
  ],
  [SupportedLanguage.Gujarati]: [
    {
      id: uuidv4(),
      label: 'સંપર્ક વ્યક્તિ',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'શિવાજી વર્મા',
      identifier: 'contactname',
    },
    {
      id: uuidv4(),
      label: 'સંપર્ક નંબર',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '9090909090',
      identifier: 'contactnumber',
    },
    {
      id: uuidv4(),
      label: 'રહેણાંક સરનામું',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '503, પ્રેમસાગર સોસાયટી, એલ.બી.એસ. માર્ગ, કુર્લા (પશ્ચિમ), મુંબઈ - 400070',
      identifier: 'address',
    },
  ],
}
