import { v4 as uuidv4 } from 'uuid'
import { FormSectionLangFieldMap } from '@/types'
import { FieldType, SupportedLanguage } from '@/enum'

export const FAMILY_DETAILS_DATA: FormSectionLangFieldMap = {
  [SupportedLanguage.English]: [
    {
      id: uuidv4(),
      label: "Father's Name",
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'Shivaji Verma',
      identifier: 'fathername',
    },
    {
      id: uuidv4(),
      label: "Father's Occupation",
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'Goldsmith',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: "Mother's Name",
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'Usha Verma',
      identifier: 'mothername',
    },
    {
      id: uuidv4(),
      label: "Mother's Occupation",
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'Housewife',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'Brother(s)',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '2 / 1 Married',
      identifier: 'brothercount',
    },
    {
      id: uuidv4(),
      label: 'Sister(s)',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '3 / 0 Married',
      identifier: 'sistercount',
    },
  ],
  [SupportedLanguage.Hindi]: [
    {
      id: uuidv4(),
      label: 'पिता का नाम',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'शिवाजी वर्मा',
      identifier: 'fathername',
    },
    {
      id: uuidv4(),
      label: 'पिता का व्यवसाय',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'सुनार',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'माँ का नाम',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'उषा वर्मा',
      identifier: 'mothername',
    },
    {
      id: uuidv4(),
      label: 'माँ का व्यवसाय',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'गृहिणी',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'भाई',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '2 / 1 विवाहित',
      identifier: 'brothercount',
    },
    {
      id: uuidv4(),
      label: 'बहन',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '3 / 0 विवाहित',
      identifier: 'sistercount',
    },
  ],
  [SupportedLanguage.Marathi]: [
    {
      id: uuidv4(),
      label: 'वडिलांचे नाव',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'शिवाजी वर्मा',
      identifier: 'fathername',
    },
    {
      id: uuidv4(),
      label: 'वडिलांचा व्यवसाय',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'सोनार',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'आईचे नाव',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'उषा वर्मा',
      identifier: 'mothername',
    },
    {
      id: uuidv4(),
      label: 'आईचा व्यवसाय',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'गृहिणी',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'भाऊ',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '२ / १ विवाहित',
      identifier: 'brothercount',
    },
    {
      id: uuidv4(),
      label: 'बहीण',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '३ / ० विवाहित',
      identifier: 'sistercount',
    },
  ],
  [SupportedLanguage.Gujarati]: [
    {
      id: uuidv4(),
      label: 'પિતાનું નામ',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'શિવાજી વર્મા',
      identifier: 'fathername',
    },
    {
      id: uuidv4(),
      label: 'પિતાનું વ્યવસાય',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'સોની',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'માતાનું નામ',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ઉષા વર્મા',
      identifier: 'mothername',
    },
    {
      id: uuidv4(),
      label: 'માતાનું વ્યવસાય',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'ગૃહિણી',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'ભાઈ(ઓ)',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '2 / 1 લગ્નગ્રસ્ત',
      identifier: 'brothercount',
    },
    {
      id: uuidv4(),
      label: 'બહેન(ઓ)',
      value: undefined,
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: '3 / 0 લગ્નગ્રસ્ત',
      identifier: 'sistercount',
    },
  ],
}
