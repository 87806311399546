'use client';

import { Autocomplete, CircularProgress, TextField, debounce } from '@mui/material';
import { AutoCompleteLabelOptionsMapping, FieldValue, LanguageCode } from '@/types';
import { useMemo, useState } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { usePathname } from 'next/navigation';
import Fuse from 'fuse.js';
import { AutoCompleteFieldLabel, SupportedLanguage } from '@/enum';
import { HINDU_CASTE_OPTIONS } from '@/app/api/data/HinduCasteOptions';
import { EDUCATION_OPTIONS } from '@/app/api/data/EducationOption';
import { GOTHRA_OPTIONS } from '@/app/api/data/GothraOptions';
import { MOTHER_TONGUE_OPTIONS } from '@/app/api/data/MotherTongueOptions';
import { NAKSHATRA_OPTIONS } from '@/app/api/data/NakshatraOptions';
import { OCCUPATION_OPTIONS } from '@/app/api/data/OccupationOptions';
import { SPECIALIZATION_OPTIONS } from '@/app/api/data/SpecializationOptions';
type AutoCompleteTextFieldProps = {
  id: string;
  value: FieldValue;
  onChangeHandler: (val: FieldValue, fieldId: string) => void;
  placeholder?: string;
  identifier: string;
};
const fuseOptions = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  ignoreLocation: true
};
const AutoCompleteLabelOptionsMap: AutoCompleteLabelOptionsMapping = {
  [AutoCompleteFieldLabel.CASTE]: HINDU_CASTE_OPTIONS,
  [AutoCompleteFieldLabel.EDUCATION]: EDUCATION_OPTIONS,
  [AutoCompleteFieldLabel.GOTHRA]: GOTHRA_OPTIONS,
  [AutoCompleteFieldLabel.MOTHER_TONGUE]: MOTHER_TONGUE_OPTIONS,
  [AutoCompleteFieldLabel.NAKSHATRA]: NAKSHATRA_OPTIONS,
  [AutoCompleteFieldLabel.OCCUPATION]: OCCUPATION_OPTIONS,
  [AutoCompleteFieldLabel.SPECIALIZATION]: SPECIALIZATION_OPTIONS
};
const AutoCompleteTextField: React.FC<AutoCompleteTextFieldProps> = ({
  id,
  value,
  onChangeHandler,
  placeholder,
  identifier
}) => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<string[]>([]);
  const pathname = usePathname();
  const locale = pathname.split('/')[1] as LanguageCode;
  const fetchOptions = (query: string, identifier: AutoCompleteFieldLabel) => {
    if (!query.length || query.length < 2) {
      setAutoCompleteOptions([]);
      return;
    }
    if (AutoCompleteLabelOptionsMap[identifier] && AutoCompleteLabelOptionsMap[identifier][locale]) {
      const fuse = new Fuse(AutoCompleteLabelOptionsMap[identifier][locale], fuseOptions);
      const results = fuse.search(query).map(result => result.item);
      setAutoCompleteOptions(results);
    } else {
      console.error('Invalid identifier or locale');
      setAutoCompleteOptions([]);
    }
  };
  return <Autocomplete freeSolo fullWidth disablePortal options={autoCompleteOptions} value={value as string} includeInputInList filterSelectedOptions getOptionLabel={option => typeof option === 'string' ? option : ''} filterOptions={x => x}
  // onChange={(_, newValue: string | null) => {
  // console.log(newValue)
  // }}
  onInputChange={(_, newVal) => {
    onChangeHandler(newVal, id);
    fetchOptions(newVal, identifier as AutoCompleteFieldLabel);
  }} renderInput={params => <TextField placeholder={placeholder} {...params} hiddenLabel size="small" InputProps={{
    ...params.InputProps
    // endAdornment: (
    //   <>
    //     {areAutoCompleteOptionsLoading ? (
    //       <CircularProgress size={20} />
    //     ) : null}
    //     {params.InputProps.endAdornment}
    //   </>
    // ),
  }} />} data-sentry-element="Autocomplete" data-sentry-component="AutoCompleteTextField" data-sentry-source-file="AutoCompleteTextField.tsx" />;
};
export default AutoCompleteTextField;