import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const COMPLEXION_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: ['Very Fair', 'Fair', 'Medium', 'Olive', 'Tan', 'Brown', 'Dark', 'Wheatish'],
  [SupportedLanguage.Hindi]: ['बहुत गोरा', 'गोरा', 'मध्यम', 'जैतूनी', 'सांवला', 'भूरा', 'गहरा', 'गेंहुआ'],
  [SupportedLanguage.Marathi]: ['अत्यंत गोरा', 'गोरा', 'मध्यम', 'ऑलिव्ह', 'तपकिरी', 'ब्राऊन', 'काळा', 'गव्हाळ'],
  [SupportedLanguage.Gujarati]: ['ખૂબ ગોરું', 'ગોરું', 'મધ્યમ', 'ઓલિવ', 'ટેન', 'બ્રાઉન', 'ડાર્ક', 'ઘઉંવર્ણ'],
  // bn: ['খুব ফর্সা', 'ফর্সা', 'মাঝারি', 'জলপাই', 'ট্যান', 'বাদামী', 'গাঢ়'],
  // te: ['చాలా తెలుపు', 'తెలుపు', 'మధ్యమ', 'ఆలివ్', 'టాన్', 'బ్రౌన్', 'డార్క్'],
  // mr: ['खूप गोरे', 'गोरे', 'मध्यम', 'ऑलिव्ह', 'टॅन', 'ब्राउन', 'डार्क'],
  // ta: [
  //   'மிகவும் வெள்ளை',
  //   'வெள்ளை',
  //   'நடுத்தரம்',
  //   'ஆலிவ்',
  //   'டேன்',
  //   'பிரவுன்',
  //   'டார்க்',
  // ],
  // gu: ['ખૂબ ગોરું', 'ગોરું', 'મધ્યમ', 'ઓલિવ', 'ટેન', 'બ્રાઉન', 'ડાર્ક'],
  // kn: ['ತುಂಬಾ ಫೇರ್', 'ಫೇರ್', 'ಮಧ್ಯಮ', 'ಆಲಿವ್', 'ಟ್ಯಾನ್', 'ಬ್ರೌನ್', 'ಡಾರ್ಕ್'],
  // ml: ['വളരെ വെളുത്ത', 'വെളുത്ത', 'ഇടത്തരം', 'ഒലിവ്', 'ടാൻ', 'ബ്രൗൺ', 'ഇരുണ്ട'],
  // pa: ['ਬਹੁਤ ਗੋਰਾ', 'ਗੋਰਾ', 'ਮੱਧ', 'ਜੈਤੂਨੀ', 'ਟੈਨ', 'ਭੂਰਾ', 'ਗਹਿਰਾ'],
  // or: ['ଖୁବ ଉଜ୍ଜ୍ଵଳ', 'ଉଜ୍ଜ୍ଵଳ', 'ମଧ୍ୟମ', 'ଜଳପାଇ', 'ଟାନ୍', 'ବାଦାମୀ', 'ଗାଢ଼'],
  // as: ['বহুত ফৰ্সা', 'ফৰ্সা', 'মধ্যম', 'জলপাই', 'টেন', 'বাদামী', 'গাঢ়'],
  // ur: ['بہت گورا', 'گورا', 'درمیانہ', 'زیتونی', 'ٹین', 'براؤن', 'گہرا'],
}
