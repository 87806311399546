'use client';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Skeleton, Stack } from '@mui/material';
import { FormFieldType } from '@/types';
import { Dispatch, SetStateAction, memo } from 'react';
import { PRIMARY, WHITE } from '@/constants/colors';
import { FieldType } from '@/enum';
import { v4 as uuidv4 } from 'uuid';
import { Add } from '@mui/icons-material';
import { useTranslations } from 'next-intl';
import FieldList from './FieldList';
type FormSectionProps = {
  title: string;
  fields: FormFieldType[];
  setFields: Dispatch<SetStateAction<FormFieldType[]>>;
};
const FormSection: React.FC<FormSectionProps> = ({
  title,
  fields,
  setFields
}) => {
  const t = useTranslations('HOMEPAGE');
  const addFieldClickHandler = () => {
    const newField: FormFieldType = {
      id: uuidv4(),
      label: '',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: false,
      identifier: '',
      placeholder: t('NEW_FIELD_VALUE_PLACEHOLDER')
    };
    setFields(prev => [...prev, newField]);
  };
  return <Box sx={{
    mb: 4
  }} data-sentry-element="Box" data-sentry-component="FormSection" data-sentry-source-file="FormSection.tsx">
      <Box sx={{
      background: PRIMARY,
      p: 2,
      borderRadius: '8px',
      mb: 4
    }} data-sentry-element="Box" data-sentry-source-file="FormSection.tsx">
        <Typography variant="h5" sx={{
        color: WHITE,
        fontWeight: 700
      }} data-sentry-element="Typography" data-sentry-source-file="FormSection.tsx">
          {title ? title : <Skeleton variant="rectangular" width="100%" height={32} />}
        </Typography>
      </Box>
      {!fields || fields.length === 0 ? <Stack spacing={2}>
          {Array.from({
        length: 10
      }, (_, index) => <Skeleton key={index} variant="rounded" width="100%" height={40} />)}
        </Stack> : <>
          <FieldList fields={fields} setFields={setFields} />
          <Button variant="outlined" onClick={addFieldClickHandler} startIcon={<Add />}>
            {t('ADD_NEW_FIELD')}
          </Button>
        </>}
    </Box>;
};
export default memo(FormSection);