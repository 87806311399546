export enum SupportedLanguage {
  English = 'en',
  Hindi = 'hi',
  // Bengali = 'bn',
  // Telugu = 'te',
  Marathi = 'mr',
  // Tamil = 'ta',
  Gujarati = 'gu',
  // Kannada = 'kn',
  // Malayalam = 'ml',
  // Punjabi = 'pa',
  // Urdu = 'ur',
  // Assamese = 'as',
  // Odia = 'or',
}

export enum SupportedReligion {
  Hinduism = 'hinduism',
  Islam = 'islam',
  Christianity = 'christianity',
  Sikhism = 'sikhism',
  Buddhism = 'buddhism',
  Jainism = 'jainism',
}

export enum FieldType {
  TEXT_FIELD = 'TEXT_FIELD',
  DATE = 'DATE',
  SELECT = 'SELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  MULTI_SELECT = 'MULTI_SELECT',
  TIME = 'TIME',
}

export enum BiodataFormSection {
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  FAMILY_DETAILS = 'FAMILY_DETAILS',
  CONTACT_DETAILS = 'PERSONAL_DETAILS',
}

export enum AutoCompleteFieldLabel {
  CASTE = 'caste',
  GOTHRA = 'gothra',
  MOTHER_TONGUE = 'mothertongue',
  NAKSHATRA = 'nakshatra',
  EDUCATION = 'education',
  OCCUPATION = 'occupation',
  SPECIALIZATION = 'specialization',
}

export enum CurrentTab {
  CHOOSE_TEMPLATE,
  CUSTOMISE_TEMPLATE,
}
