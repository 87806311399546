import { SupportedReligion } from '@/enum';
import { HeaderIcon } from '@/types';
import ICON_001 from 'public/header-icons/001.svg?url';
import ICON_002 from 'public/header-icons/002.svg?url';
import ICON_004 from 'public/header-icons/004.svg?url';
import ICON_005 from 'public/header-icons/005.svg?url';
import ICON_006 from 'public/header-icons/006.svg?url';
import ICON_007 from 'public/header-icons/007.svg?url';
import ICON_008 from 'public/header-icons/008.svg?url';
import ICON_010 from 'public/header-icons/010.svg?url';
import ICON_011 from 'public/header-icons/011.svg?url';
import ICON_012 from 'public/header-icons/012.svg?url';
import ICON_013 from 'public/header-icons/013.svg?url';
import ICON_014 from 'public/header-icons/014.svg?url';
import ICON_015 from 'public/header-icons/015.svg?url';
import ICON_016 from 'public/header-icons/016.svg?url';
import ICON_017 from 'public/header-icons/017.svg?url';
import ICON_018 from 'public/header-icons/018.svg?url';
import ICON_020 from 'public/header-icons/020.svg?url';
import ICON_021 from 'public/header-icons/021.svg?url';
import ICON_023 from 'public/header-icons/023.svg?url';
import ICON_024 from 'public/header-icons/024.svg?url';
import ICON_025 from 'public/header-icons/025.svg?url';
import ICON_026 from 'public/header-icons/026.svg?url';
import ICON_027 from 'public/header-icons/027.svg?url';
import ICON_028 from 'public/header-icons/028.svg?url';
export const HEADER_ICON_OPTIONS: HeaderIcon[] = [{
  id: 'ICON_001',
  meta: ICON_001,
  religion: SupportedReligion.Hinduism,
  isCustomisable: true
}, {
  id: 'ICON_002',
  meta: ICON_002,
  religion: SupportedReligion.Hinduism,
  isCustomisable: true
}, {
  id: 'ICON_004',
  meta: ICON_004,
  religion: SupportedReligion.Hinduism,
  isCustomisable: true
}, {
  id: 'ICON_005',
  meta: ICON_005,
  religion: SupportedReligion.Hinduism,
  isCustomisable: true
}, {
  id: 'ICON_006',
  meta: ICON_006,
  religion: SupportedReligion.Hinduism,
  isCustomisable: true
}, {
  id: 'ICON_007',
  meta: ICON_007,
  religion: SupportedReligion.Hinduism,
  isCustomisable: false
}, {
  id: 'ICON_008',
  meta: ICON_008,
  religion: SupportedReligion.Buddhism,
  isCustomisable: false
}, {
  id: 'ICON_010',
  meta: ICON_010,
  religion: SupportedReligion.Buddhism,
  isCustomisable: false
}, {
  id: 'ICON_011',
  meta: ICON_011,
  religion: SupportedReligion.Buddhism,
  isCustomisable: true
}, {
  id: 'ICON_012',
  meta: ICON_012,
  religion: SupportedReligion.Buddhism,
  isCustomisable: true
}, {
  id: 'ICON_017',
  meta: ICON_017,
  religion: SupportedReligion.Islam,
  isCustomisable: true
}, {
  id: 'ICON_018',
  meta: ICON_018,
  religion: SupportedReligion.Islam,
  isCustomisable: true
}, {
  id: 'ICON_013',
  meta: ICON_013,
  religion: SupportedReligion.Christianity,
  isCustomisable: true
}, {
  id: 'ICON_014',
  meta: ICON_014,
  religion: SupportedReligion.Christianity,
  isCustomisable: true
}, {
  id: 'ICON_015',
  meta: ICON_015,
  religion: SupportedReligion.Christianity,
  isCustomisable: true
}, {
  id: 'ICON_016',
  meta: ICON_016,
  religion: SupportedReligion.Christianity,
  isCustomisable: true
}, {
  id: 'ICON_028',
  meta: ICON_028,
  religion: SupportedReligion.Christianity,
  isCustomisable: true
}, {
  id: 'ICON_020',
  meta: ICON_020,
  religion: SupportedReligion.Sikhism,
  isCustomisable: true
}, {
  id: 'ICON_021',
  meta: ICON_021,
  religion: SupportedReligion.Sikhism,
  isCustomisable: true
}, {
  id: 'ICON_023',
  meta: ICON_023,
  religion: SupportedReligion.Jainism,
  isCustomisable: true
}, {
  id: 'ICON_024',
  meta: ICON_024,
  religion: SupportedReligion.Jainism,
  isCustomisable: true
}, {
  id: 'ICON_025',
  meta: ICON_025,
  religion: SupportedReligion.Jainism,
  isCustomisable: true
}, {
  id: 'ICON_026',
  meta: ICON_026,
  religion: SupportedReligion.Jainism,
  isCustomisable: true
}, {
  id: 'ICON_027',
  meta: ICON_027,
  religion: SupportedReligion.Jainism,
  isCustomisable: true
}];