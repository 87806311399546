import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { memo, useState } from 'react';
import logo from 'public/logo.png';
import Image from 'next/image';
import { BORDER_GREY, WHITE } from '@/constants/colors';
import { CloseOutlined, SettingsOutlined } from '@mui/icons-material';
import { Divider, Drawer, LinearProgress, Link, Select, SelectChangeEvent, Stack } from '@mui/material';
import { useSettings } from '@/providers/SettingsProvider';
import { usePathname, useRouter } from 'next/navigation';
import { useTranslations } from 'use-intl';
import { LANGUAGE_OPTIONS, RELIGION_OPTIONS } from '@/constants/options';
type TopAppBarProps = {};
const TopAppBar: React.FC<TopAppBarProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const {
    settings,
    updateSetting
  } = useSettings();
  const t = useTranslations('HOMEPAGE');
  const router = useRouter();
  const pathname = usePathname();
  const locale = pathname.split('/')[1];
  const [tempLocale, setTempLocale] = useState<string>(locale);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const handleLanguageChange = (event: SelectChangeEvent) => {
    const nextLocale = event.target.value;
    setTempLocale(nextLocale);
    setShowLoader(true);
    closeDrawer();
    router.replace(`/${nextLocale}`);
    // enqueueSnackbar(
    //   texts[event.target.value as SupportedLanguage].SETTINGS.SETTINGS_UPDATED,
    //   {
    //     variant: 'default',
    //     TransitionProps: { direction: 'up' },
    //   },
    // )
  };
  const handleReligionChange = (event: SelectChangeEvent) => {
    updateSetting('religion', event.target.value);
    // enqueueSnackbar(SETTINGS.SETTINGS_UPDATED, {
    //   variant: 'default',
    //   TransitionProps: { direction: 'up' },
    // })
  };
  return <>
      <AppBar position="fixed" sx={{
      background: WHITE
    }} data-sentry-element="AppBar" data-sentry-source-file="TopAppBar.tsx">
        <Container maxWidth="lg" data-sentry-element="Container" data-sentry-source-file="TopAppBar.tsx">
          <Toolbar disableGutters data-sentry-element="Toolbar" data-sentry-source-file="TopAppBar.tsx">
            <Box sx={{
            width: {
              xs: '120px',
              md: '180px'
            },
            display: 'flex',
            alignItems: 'center'
          }} data-sentry-element="Box" data-sentry-source-file="TopAppBar.tsx">
              <Link href="https://marriagebiodatamaker.app" sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }} data-sentry-element="Link" data-sentry-source-file="TopAppBar.tsx">
                <Image src={logo} style={{
                maxWidth: '100%',
                height: 'auto',
                marginRight: 1
              }} alt="Marriage Biodata Maker" data-sentry-element="Image" data-sentry-source-file="TopAppBar.tsx" />
              </Link>
            </Box>

            <Stack flexDirection="row" alignItems="center" gap={2} sx={{
            ml: 'auto'
          }} data-sentry-element="Stack" data-sentry-source-file="TopAppBar.tsx">
              <Select value={tempLocale} onChange={handleLanguageChange} size="small" sx={{
              width: '160px'
            }} data-sentry-element="Select" data-sentry-source-file="TopAppBar.tsx">
                {LANGUAGE_OPTIONS.map(lang => <MenuItem key={lang.code} value={lang.code}>
                    {lang.label}
                  </MenuItem>)}
              </Select>
              {/* <IconButton size="medium" onClick={openDrawer} color="primary" sx={{ border: `1px solid ${BORDER_GREY}` }}>
                <SettingsOutlined />
               </IconButton> */}
            </Stack>
          </Toolbar>
        </Container>
        {showLoader && <Box sx={{
        width: '100%'
      }}>
            <LinearProgress />
          </Box>}
      </AppBar>

      <Drawer open={open} onClose={closeDrawer} anchor="right" sx={{
      '& .MuiDrawer-paper': {
        borderRadius: '12px 0 0 12px',
        p: 3
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="TopAppBar.tsx">
        <Box sx={{
        width: '300px',
        overflow: 'hidden'
      }} data-sentry-element="Box" data-sentry-source-file="TopAppBar.tsx">
          <Stack direction="row" justifyContent="space-between" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="TopAppBar.tsx">
            <Typography sx={{
            fontWeight: 600
          }} data-sentry-element="Typography" data-sentry-source-file="TopAppBar.tsx">{t('SETTINGS_TITLE')}</Typography>
            <IconButton onClick={closeDrawer} data-sentry-element="IconButton" data-sentry-source-file="TopAppBar.tsx">
              <CloseOutlined data-sentry-element="CloseOutlined" data-sentry-source-file="TopAppBar.tsx" />
            </IconButton>
          </Stack>
          <Divider sx={{
          my: 2
        }} data-sentry-element="Divider" data-sentry-source-file="TopAppBar.tsx" />
          <Box sx={{
          mb: 3
        }} data-sentry-element="Box" data-sentry-source-file="TopAppBar.tsx">
            <Typography variant="overline" display="block" fontWeight="500" data-sentry-element="Typography" data-sentry-source-file="TopAppBar.tsx">
              {t('LANGUAGE')}
            </Typography>
            <Select value={tempLocale} onChange={handleLanguageChange} size="small" sx={{
            width: '200px'
          }} data-sentry-element="Select" data-sentry-source-file="TopAppBar.tsx">
              {LANGUAGE_OPTIONS.map(lang => <MenuItem key={lang.code} value={lang.code}>
                  {lang.label}
                </MenuItem>)}
            </Select>
          </Box>
          <Box sx={{
          mb: 3
        }} data-sentry-element="Box" data-sentry-source-file="TopAppBar.tsx">
            <Typography variant="overline" display="block" fontWeight="500" data-sentry-element="Typography" data-sentry-source-file="TopAppBar.tsx">
              {t('RELIGION')}
            </Typography>
            <Select value={settings.religion} onChange={handleReligionChange} size="small" sx={{
            width: '200px'
          }} data-sentry-element="Select" data-sentry-source-file="TopAppBar.tsx">
              {RELIGION_OPTIONS.map(religion => <MenuItem key={religion.code} value={religion.code}>
                  {religion.label}
                </MenuItem>)}
            </Select>
          </Box>
        </Box>
      </Drawer>
    </>;
};
export default memo(TopAppBar);